import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [3,2];

export const dictionary = {
		"/(dash)": [~8,[3]],
		"/(auth)/auth/forgot-password": [4,[2]],
		"/(auth)/auth/login": [5,[2]],
		"/(auth)/auth/register": [6,[2]],
		"/(auth)/auth/reset-password": [7,[2]],
		"/(dash)/forms": [9,[3]],
		"/(dash)/lessons": [~10,[3]],
		"/(dash)/lessons/[id]": [~11,[3]],
		"/(dash)/media": [~12,[3]],
		"/(dash)/pages": [13,[3]],
		"/(dash)/pages/[id]": [~14,[3]],
		"/(dash)/playlists": [15,[3]],
		"/(dash)/playlists/[id]": [~16,[3]],
		"/(dash)/posts": [17,[3]],
		"/(dash)/posts/[id]": [~18,[3]],
		"/(dash)/table": [19,[3]],
		"/(dash)/testimonials": [20,[3]],
		"/(dash)/testimonials/[id]": [~21,[3]],
		"/(dash)/users": [22,[3]],
		"/(dash)/users/[id]": [~23,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';
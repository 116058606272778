import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://e461466b216f47d4825b345973c387b5@o1352253.ingest.sentry.io/6647551',
  integrations: [Sentry.browserTracingIntegration()],
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE === 'production',
  tracesSampleRate: 0.5
});

export function handleError({ error, event, status }) {
  const errorId = crypto.randomUUID();
  Sentry.captureException(error, {
    extra: { event, errorId, status }
  });
}
